<template>
	<div>
		<el-card>
			<el-row>
				<el-button type="primary" @click="add">添加</el-button>
			</el-row>
			<!-- 服务类型表单 -->
			<el-table :data="list" style="width: 100%" :header-cell-style="{ background: '#eef1f6', color: '#606266' }">
				<el-table-column type="index" label="序号"></el-table-column>
        <el-table-column label="客户推广费用" width="220" v-if="!type_id">
          <template slot-scope="scope">
            <div style="white-space: nowrap;">客户前{{scope.row.custom_before_bs}}笔，分佣比例{{scope.row.custom_before_bl}}%</div>
          </template>
        </el-table-column>
        <el-table-column label="技师费用" width="220">
          <template slot-scope="scope">
            <div style="white-space: nowrap;">技师前{{scope.row.before_month}}个月，分佣比例{{scope.row.before_bl}}%</div>
          </template>
        </el-table-column>
				<el-table-column prop="fwf_bl" label="服务费">
          <template slot-scope="scope"><div>{{scope.row.fwf_bl}}%</div></template>
        </el-table-column>
				<el-table-column prop="tixian_bl" label="车费提现手续费">
          <template slot-scope="scope"><div>{{scope.row.tixian_bl}}%</div></template>
        </el-table-column>
				<el-table-column prop="store_bl" label="门店分成比例">
          <template slot-scope="scope"><div>{{scope.row.store_bl}}%</div></template>
        </el-table-column>
				<el-table-column prop="store_jishi_bl" label="门店技师分成比例">
          <template slot-scope="scope"><div>{{scope.row.store_jishi_bl}}%</div></template>
        </el-table-column>
				<el-table-column prop="add_by" label="创建人"></el-table-column>
				<el-table-column prop="add_time" label="创建时间"></el-table-column>
			</el-table>
			<!-- 分页 -->
			<Pagination :queryInfo="queryInfo" :total="total" @handleSizeChange="handleSizeChange" @handleCurrentChange="handleCurrentChange"></Pagination>

      <!-- 费用设置 -->
      <el-dialog title="费用设置" width="700px" :visible.sync="feeDialog">
        <el-form ref="feeForm" :model="feeForm" label-width="130px" :rules="feeRules">
          <el-row>
            <el-col :span="12" v-if="!type_id">
              <el-form-item label="客户前" prop="before_month">
                <el-input v-model="feeForm.custom_before_bs" type="number" placeholder="前几个" oninput="if(value<1)value=0;">
                  <template #append>笔</template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="3" class="flex-box" v-if="!type_id">
              分佣比例
            </el-col>
            <el-col :span="7" v-if="!type_id">
              <el-form-item label="" prop="before_bl" label-width="10px">
                <el-input v-model="feeForm.custom_before_bl" type="number" placeholder="请输入" oninput="if(value<0)value=0; else if(value>15)value=15">
                  <template #append>%</template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="技师前" prop="before_month">
                <el-input v-model="feeForm.before_month" type="number" placeholder="前几个月" oninput="if(value<1)value=1; else if(value>12)value=12">
                  <template #append>月</template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="3" class="flex-box">
              分佣比例
            </el-col>
            <el-col :span="7">
              <el-form-item label="" prop="before_bl" label-width="10px">
                <el-input v-model="feeForm.before_bl" type="number" placeholder="请输入" oninput="if(value<0)value=0; else if(value>99)value=99">
                  <template #append>%</template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="22">
              <el-form-item label="最低提现额度" prop="min_txje">
                <el-input v-model="feeForm.min_txje" type="number" placeholder="请输入最低提现额度" oninput="if(value<0)value=0; else if(value>100)value=100">
                  <template #append>元</template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="2" class="flex-box">
              <el-tooltip placement="right" class="tooltips">
                <div slot="content">技师/代理/经理/门店最低可提现额度</div>
                <i class="el-icon-question"></i>
              </el-tooltip>
            </el-col>
            <el-col :span="22">
              <el-form-item label="服务费扣费" prop="fwf_bl">
                <el-input v-model="feeForm.fwf_bl" type="number" placeholder="请输入服务费扣费百分比" max="6" oninput="if(value<0)value=0; else if(value>6)value=6">
                  <template #append>%</template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="2" class="flex-box">
              <el-tooltip placement="right" class="tooltips">
                <div slot="content">服务费扣费最大不能超过6%</div>
                <i class="el-icon-question"></i>
              </el-tooltip>
            </el-col>
            <el-col :span="22">
              <el-form-item label="车费提现手术费" prop="tixian_bl">
                <el-input v-model="feeForm.tixian_bl" type="number" placeholder="请输入提现手术费" oninput="if(value<0)value=0; else if(value>10)value=10">
                  <template #append>%</template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="22">
              <el-form-item label="门店分成比例" prop="store_bl">
                <el-input v-model="feeForm.store_bl" type="number" placeholder="请输入门店费用比例" max="50" oninput="if(value<0)value=0; else if(value>50)value=50">
                  <template #append>%</template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="22">
              <el-form-item label="门店技师分成比例" prop="store_jishi_bl">
                <el-input v-model="feeForm.store_jishi_bl" type="number" placeholder="请输入门店技师分成比例" max="50" oninput="if(value<0)value=0; else if(value>50)value=50">
                  <template #append>%</template>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-button type="default" @click="feeDialog=false">取消</el-button>
          <el-button type="primary" @click="doSetFee">确定</el-button>
        </el-form>
      </el-dialog>
		</el-card>
	</div>
</template>

<script>
  let that;
	let timer = null;
	import Pagination from '@/components/Pagination/Pagination.vue'
  import {addFee, updateTypeFee} from '@/api/request/service'
  import ImageUpload from '@/components/Upload/ImageUpload.vue'
	export default {
    props:{
      type_id:{
        type: Number,
        default(){
          return 0
        }
      },
    },
		components: {
			Pagination,ImageUpload
		},
		data() {
			return {
				title: "新增服务类型",
				queryInfo: {
          type_id: this.type_id,
					pagenum: 1,
					pagesize: 10,
				},
				total: 0,
        list: [],
        feeDialog: false,
        feeForm: {
          type_id: this.type_id,
          custom_before_bs: '',
          custom_before_bl: '',
          before_month: '',
          before_bl: '',
          first_bl: '',
          add_bl: '',
          min_txje: '',
          qudao_bl: '',
          fwf_bl: '',
          tixian_bl: '',
          store_bl: '',
          store_jishi_bl: ''
        },
        feeRules: {
          min_txje: [{required: true, message: "请输入最小提现金额", trigger: "blur"},],
          fwf_bl: [{required: true, message: "请输入服务费扣费", trigger: "blur"},]
        },
			}
		},
		created() {
      that = this;
      this.getRecord();
		},
		methods: {
			getRecord() {
				var url = 'fee_setting_record/record_list';
				let params = {
					type_id: this.queryInfo.type_id,
					size: this.queryInfo.pagesize,
					page: this.queryInfo.pagenum,
				};
				this.fd_post(url, params).then((res) => {
					if(res.status) {
						that.total = res.total;
						that.list = res.list;
					} else {
						that.$message.error(res.msg);
					}
				}).catch((err) => {
					that.$message.error('网络错误');
				});
			},
			//页数改变
			handleSizeChange(newSize) {
				this.queryInfo.pagenum = 1;
				this.queryInfo.pagesize = newSize;
				this.getRecord();
			},
			//页码改变
			handleCurrentChange(newPage) {
				this.queryInfo.pagenum = newPage;
				this.getRecord();
			},
      //确认设置
      doSetFee() {
        this.$refs.feeForm.validate(async valid => {
          if (!valid) {
            return;
          } else {
            const res = await addFee(this.feeForm).catch((err) => {
              this.$message.error('网络错误');
            });
            if (res.status === 1) {
              this.feeDialog = false;
              this.$refs.feeForm.resetFields();
              this.$message.success('操作成功');
              this.getRecord();
            } else {
              this.$message.error(res.msg);
            }
          }
        });
      },
      //打开对话框
      add() {
        this.feeDialog = true
        this.feeForm = {
          type_id: this.type_id,
          custom_before_bs: '',
          custom_before_bl: '',
          before_month: '',
          before_bl: '',
          first_bl: '',
          add_bl: '',
          min_txje: '',
          qudao_bl: '',
          fwf_bl: '',
          tixian_bl: '',
          store_bl: '',
          store_jishi_bl: ''
        }
        // this.feeForm.add_bl = parseFloat(item.setting.add_bl)
        // this.feeForm.before_bl = parseFloat(item.setting.before_bl)
        // this.feeForm.before_month = parseFloat(item.setting.before_month)
        // this.feeForm.first_bl = parseFloat(item.setting.first_bl)
        // this.feeForm.fwf_bl = parseFloat(item.setting.fwf_bl)
        // this.feeForm.min_txje = parseFloat(item.setting.min_txje)
        // this.feeForm.qudao_bl = parseFloat(item.setting.qudao_bl)
        // this.feeForm.store_bl = parseFloat(item.setting.store_bl)
        // this.feeForm.store_jishi_bl = parseFloat(item.setting.store_jishi_bl)
        // this.feeForm.tixian_bl = parseFloat(item.setting.tixian_bl)
        // this.feeForm.type_id = item.setting.type_id
      },
		},
	}
</script>

<style lang="scss" scoped>
.el-row{
  text-align: left;
  margin-bottom: 20px;
}
.flex-box{
  display: flex;
  align-items: center;
  height: 38px;
  padding-left: 10px;
}
</style>